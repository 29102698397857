.slider {
  padding: 22px 0px;
}

.zoom-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 5px;
  background: #1da1f2;
  width: 100%;
  border-radius: 3vw;
}

.zoom-range::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid white;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.zoom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid white;
  background: #1da1f2;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
