body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	box-sizing: border-box;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

/* general app styles */

.app-height {
	height: 100vh;
}

.app-minHeight {
	min-height: 100vh;
}

a,
a:hover {
	text-decoration: none !important;
}

.flex-center-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.flex-between-center {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.flex-1 {
	flex: 1 !important;
}

.cr-pointer {
	cursor: pointer;
}

.max-50 {
	max-width: 50px !important;
}

.max-60 {
	max-width: 60px !important;
}

.max-80 {
	max-width: 80px !important;
}

.max-100 {
	max-width: 100px !important;
	margin: 0 auto;
}

.max-106 {
	max-width: 100px !important;
	margin: 0 auto;
}

.width-90 {
	width: 90px !important;
}

.w-235 {
	width: 235px !important;
}

.w-150 {
	max-width: 150px !important;
	width: 100% !important;
}

.w-60 {
	width: 60% !important;
}

.w-49_5 {
	width: 49.5% !important;
}

.wh-unset {
	width: unset !important;
	height: unset !important;
}

.max-214 {
	max-width: 214px !important;
}

.w-214 {
	width: 214px !important;
}

.max-235 {
	max-width: 235px !important;
	margin: 0 auto;
}

.max-238 {
	max-width: 238px !important;
	margin: 0 auto;
}

.max-416 {
	max-width: 416px;
	width: 100%;
	margin: 0 auto;
}

.max-450 {
	max-width: 450px !important;
	width: 100%;
}

.height-25 {
	height: 25px !important;
}

.bg-pink {
	background-color: #ef1955 !important;
}

.bg-lightGray {
	background-color: #f7f7f7 !important;
}

.mr-16 {
	margin-right: 16px !important;
}

.mb-16 {
	margin-bottom: 16px !important;
}

.mb-18 {
	margin-bottom: 18px !important;
}

.mt-18 {
	margin-top: 18px !important;
}

.mb-24 {
	margin-bottom: 24px !important;
}

.mt--25 {
	margin-top: -25px !important;
}

.mt-24 {
	margin-top: 24px !important;
}

.mb-45 {
	margin-bottom: 45px !important;
}

.mb-76 {
	margin-bottom: 76px !important;
}

.mb-85 {
	margin-bottom: 85px !important;
}

.p-10 {
	padding: 10px !important;
}

.p-24 {
	padding: 24px !important;
}

.br-12 {
	border-radius: 12px !important;
}

.f-12 {
	font-size: 12px !important;
}

.f-14 {
	font-size: 14px !important;
}

.f-15 {
	font-size: 15px !important;
}

.f-16 {
	font-size: 16px !important;
}

.f-18 {
	font-size: 18px !important;
}

.f-24 {
	font-size: 24px !important;
}

.f-100 {
	font-size: 100px !important;
}

.fw-bold {
	font-weight: bold !important;
}

.fw-normal {
	font-weight: normal !important;
}

.fw-400 {
	font-weight: 400 !important;
}

.fw-500 {
	font-weight: 500 !important;
}

.app-input {
	height: 47px;
	width: 100%;
	border-radius: 2px;
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 0.2px;
	line-height: 20px;
	padding-left: 8px;
	background-color: #f7f7f7;
	margin-bottom: 16px;
	border: none;
	outline: none;
}

textarea.app-input {
	height: unset !important;
}

.app-input.search,
.addAdmin .app-input,
.small .app-input {
	height: 25px;
	margin-bottom: 0;
	font-size: 12px;
}

.date-input {
	height: 25px;
	font-size: 12px;
	color: #888888;
	border: none;
	outline: none;
	/* width: 260px; */
}

.text-gray {
	color: #888888 !important;
}

.text-deepBlack {
	color: #001935;
}

.app-button {
	height: 37px;
	width: 235px;
	background: #c4c4c4;
	border-radius: 4px;
	outline: none;
	border: none;
	color: white;
	font-size: 14px;
}

.button-transparent {
	background: transparent;
	border-radius: 4px;
	border: 1px solid #888888 !important;
	outline: none;
	border: none;
	color: #888888;
	font-size: 12px;
	padding: 3px 10px;
}

.button-transparent.active {
	border: 1px solid #1da1f2 !important;
	color: #1da1f2 !important;
}

.right-content {
	flex: 1;
	display: flex;
	flex-direction: column;
}

.main-content {
	flex: 1;
	background-color: white;
	padding: 25px;
}

.img-round {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	overflow: hidden;
}

.img-round img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

button:focus,
.btn-primary:focus {
	outline: none !important;
	/* border: none !important; */
}

/* sidebar styles */

.sidebar {
	width: 270px;
	background-color: #f7f7f7;
	padding-top: 10px;
}

.side-link {
	padding: 16px;
	cursor: pointer;
	width: 100%;
}

.side-link a {
	font-size: 16px;
	font-weight: bold;
	color: #001935;
	text-decoration: none;
	/* max-width: 118px; */
	display: block;
	text-align: left;
	margin-left: 31%;
}

.side-link a:hover {
	text-decoration: none;
	color: #001935;
}

/* topnav styles */

.topnav {
	background-color: #f7f7f7;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	padding: 10px 30px;
}

/* dashboard styles */
.data-period {
	display: flex;
	padding: 24px;
	padding-right: 60px;
	background-color: #f7f7f7;
	margin-bottom: 16px;
	border-radius: 12px;
}

.statistics {
	padding: 27px;
	display: flex;
	justify-content: space-evenly;
	background-color: #f7f7f7;
	border-radius: 12px;
	margin-bottom: 16px;
}

.info {
	display: flex;
	margin-bottom: 16px;
}

.info > div {
	width: 49.3%;
	background-color: #f7f7f7;
	border-radius: 12px;
	padding: 24px;
}

.info > div:first-child {
	margin-right: 1.5%;
}

.table {
	border-collapse: separate;
	border-spacing: 0 9px;
	border-top-right-radius: 12px;
	overflow: hidden;
}

.table thead th {
	color: #1da1f2 !important;
	/* background-color: #001935; */
	font-weight: 700;
	font-size: 12px;
	font-family: "Montserrat";
	text-align: center;
}

.table2 thead th {
	color: #ffffff !important;
	background-color: #001935;
	font-weight: 700;
	font-size: 12px;
	font-family: "Montserrat";
	text-align: center;
}

.table tbody td {
	font-size: 14px;
	font-weight: normal;
	text-align: center;
	vertical-align: middle;
	padding: 10px;
}

.table2 tbody {
	background-color: white;
}
